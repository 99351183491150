import { useEffect, useState } from "react";
import { backendUrl } from "./utils/environment";

function App() {
  const [message, setMessage] = useState(localStorage.getItem("message") ?? "");

  useEffect(() => {
    const message = localStorage.getItem("message");
    const lastMessageDate = localStorage.getItem("lastMessageDate") ?? "";
    const milliseconds = parseInt(lastMessageDate);
    const dateNow = new Date().setHours(0, 0, 0, 0);
    const lastDate = new Date(milliseconds).setHours(0, 0, 0, 0);

    if (
      !lastMessageDate ||
      (lastMessageDate && !message) ||
      (/^\d+$/.test(lastMessageDate) &&
        !isNaN(milliseconds) &&
        lastDate < dateNow)
    ) {
      fetch(`${backendUrl()}/message/`)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          return "";
        })
        .then((res) => {
          if (res !== "") {
            setMessage(res);
            localStorage.setItem("message", res);

            localStorage.setItem("lastMessageDate", dateNow.toString());
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  return (
    <div className="mx-4 md:mx-auto md:max-w-3xl py-12 text-xl">
      <p className="text-[#6C3408]">
        {message !== ""
          ? message
          : "The waves keep stretching towards the shore, but no thoughts reach your hands..."}
      </p>
    </div>
  );
}

export default App;
