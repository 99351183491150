export const backendUrl = () => {
  if (window.location.hostname === "frontend.flaskepost.app.iterate.no") {
    return "https://backend.flaskepost.app.iterate.no";
  } else if (
    window.location.hostname === "frontend.flaskepost.test.iterate.no"
  ) {
    return "https://backend.flaskepost.test.iterate.no";
  } else if (window.location.hostname === "localhost") {
    return "http://localhost:8080";
  } else if (window.location.hostname === "127.0.0.1") {
    return "http://localhost:8080";
  } else {
    return "https://backend.flaskepost.test.iterate.no";
  }
};
